import SuperRepository from '../superRepository'

export default new (class PlayersReportsRepository extends SuperRepository {
  baseUri() {
    return 'player_report'
  }
  generalReport = async params =>
    this.httpClient().post(`${this.baseUri()}/general_report`, params)
  generalReportOld = async params =>
    this.httpClient().post(`${this.baseUri()}/general_report_old`, params)
  generalReportNew = async params =>
    this.httpClient().post(`${this.baseUri()}/general_report_new`, params)
  generalReportExport = async (data, params) =>
    this.httpClient().postFile(`${this.baseUri()}/general_report`, data, params)
  cohortAnalysis = async params =>
    this.httpClient().post(`${this.baseUri()}/cohort_analysis`, params)
  cohortAnalysisOld = async params =>
    this.httpClient().post(`${this.baseUri()}/cohort_analysis_old`, params)
  cohortAnalysisExport = async (data, params) =>
    this.httpClient().postFile(`${this.baseUri()}/cohort_analysis`, data, params)
  costsReportExport = async (data, params) =>
    this.httpClient().postFile(`${this.baseUri()}/cost_report`, data, params)
})()